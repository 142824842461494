@tailwind base;
@tailwind components;
@tailwind utilities;
/*body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}*/

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    @apply bg-white;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}

h1 {
  font-size: 24px;
}

main {
    @apply bg-white;
}

.artists_data {
    @apply mb-20;
}



.newrequest a {
    background-color: goldenrod;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    padding: 10px;
    margin: 10px 0;
  float:right;
}
.newrequest:hover a {
    background-color: blue;
    color: white;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    padding: 10px;
    margin: 10px 0;
  float:right;
}


.facebook-btn {
    background-color: #3b5998;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.facebook-btn:hover {
    background-color: #2d4373;
}

.facebook-btn img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.loader-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 1.5rem;
    color: #000;
}



.get-details-container {
    padding: 20px;
    text-align: center;
}
.get-details-content {
    background-color: #ffffff;
    margin: auto;
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
}
.get-details-footer {
    text-align: center;
    font-size: 14px;
}
