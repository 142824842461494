/* src/components/SignInWithGoogle.css */
.google-login-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #4285F4;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.google-login-button:hover {
    background-color: #357ae8;
}

.google-login-button:focus {
    outline: none;
}

.google-login-button img {
    margin-right: 8px;
}
