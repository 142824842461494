.audio-player {
    display: flex;
    align-items: center;
    gap: 10px;
}

.audio-player button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #1f2937;
    color: white;
}

.audio-player button:hover {
    background-color: #374151;
}